<script setup lang="ts">
import { FulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { useEventListener } from '@vueuse/core';

const orderTypeStore = useOrderTypeStore();
const metadataStore = useMetadataStore();

const { tableNumber, fulfillmentMode } = storeToRefs(orderTypeStore);
const { kioskSettings } = storeToRefs(metadataStore);

useEventListener('paste', (event) => {
  event.preventDefault();
  const pastedData = event.clipboardData?.getData('text');
  if (pastedData) {
    tableNumber.value = pastedData.replace(/\D/g, '');
  }
});

const inputRef = ref<HTMLInputElement | null>(null);
const showKeyboard = ref(false);

const showQrScanner = computed(() => {
  if (fulfillmentMode.value === FulfillmentMode.DINE_IN) {
    return kioskSettings.value?.fulfillment.dineIn.settings.qrScanner ?? false;
  }

  if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
    return kioskSettings.value?.fulfillment.pickUp.settings.qrScanner ?? false;
  }

  return false;
});

const emit = defineEmits(['next']);
const collapse = defineModel({
  type: Boolean,
  required: true,
});

function closeKeyboard() {
  inputRef.value?.blur();
  showKeyboard.value = false;
  emit('next');
}

function pickUpCounter() {
  tableNumber.value = '';
  closeKeyboard();
}
</script>

<template>
  <CollapsableContainer v-model="collapse" :show-collapse="false" :position="1" :title="$t('CHECKOUT.TABLE')">
    <section class="flex flex-col items-center gap-8">
      <div
        class="custom-input-container"
      >
        <div class="customer-item">
          <span
            class="block icon-table-bar ml-2 text-6xl icon"
          />
          <label>
            <span class="relative pr-5 text-2xl">
              {{ $t('TABLE') }}
            </span>

            <input
              v-model="tableNumber"
              :placeholder="$t('TABLE_NUMBER')"
              class="item-input"
              ref="inputRef"
              @focus="showKeyboard = true"
            />

            <p
              v-if="showQrScanner"
              class="flex gap-2 py-3 text-lg italic leading-none label-text-alt text-info"
            >
              <span class="icon icon-info-circle" /> {{ $t('TABLE_HINT') }}
            </p>
          </label>
        </div>
      </div>

      <div class="flex gap-5 text-3xl w-[35rem] items-center">
        <div class="separator w-full h-0.5 bg-neutral-400 my-4" />
        <span class="flex-grow text-nowrap">{{ $t('CHECKOUT.YOU_CAN_ALSO') }}</span>
        <div class="separator w-full h-0.5 bg-neutral-400 my-4" />
      </div>

      <KioskButton text-size="small" class="text-3xl" @click="pickUpCounter()">
        {{ $t("PICK_UP_COUNTER") }}
      </KioskButton>
    </section>
  </CollapsableContainer>

  <SlideTransition
    class="fixed bottom-0"
  >
    <template v-if="showKeyboard">
      <NumbersKeyboard
        v-model="tableNumber"
        :input-ref="inputRef"
        :max-length="2"
        @close="closeKeyboard()"
      />
    </template>
  </SlideTransition>
</template>

<style scoped>
.custom-input-container {
  @apply flex flex-col justify-center gap-2 w-[556px];
}

.customer-info {
  @apply flex flex-col items-center justify-center gap-10 my-2 p-8;
}

.customer-item {
  @apply shadow-xl flex justify-center items-center gap-8 px-5 py-3 w-full;
}

.item-input {
  @apply h-14 w-96 bg-neutral-200 placeholder:text-neutral-300 text-[32px] font-medium leading-[101px] tracking-[-1.263px] px-4 focus:outline-none;
}
</style>
