<script setup lang="ts">
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { StepWizardName } from '@/common/enums/stepWizardName';
import { Customer } from '../interfaces/customer';
import { CustomerItem } from '../interfaces/customerItem';

const { t } = useI18n();
const router = useRouter();
const cartStoreV2 = useCartStoreV2();
const { itemsCount } = storeToRefs(cartStoreV2);
const webhookStore = useWebhookStoreV2();
const metadataStore = useMetadataStore();
const { customer, jobResponse, paymentInProgress } = storeToRefs(webhookStore);
const {
  clusterSettings,
  showClientType,
  timelineImage,
} = storeToRefs(metadataStore);
const { triggerGoBack } = useGTMEventsComposableV2();
const route = useRoute();
const { steps, stepIndex } = useStepWizard();
const { closeCurrencyModal, isOpenCurrencyModal } = useCurrencyModal();
const couponStore = useCouponStore();

const hideSummary = ref(false);

const collapseSteps = ref([
  {
    collapse: false,
    checked: false,
    name: 'customerInfo',
  },
  {
    collapse: true,
    checked: false,
    name: 'tableNumber',
  },
  {
    collapse: true,
    checked: false,
    name: 'payment',
  },
]);

const currentStepIndex = computed(() => collapseSteps.value.findIndex((stp) => stp.collapse === false));

const orderTotal = computed((): Partial<HubsterOrderTotal> => {
  const { subtotal, taxes } = cartStoreV2;

  const total = subtotal - couponStore.discount;
  const subtotalWithoutTaxes = subtotal - taxes;

  return {
    subtotal: taxes === 0 ? subtotal : subtotalWithoutTaxes,
    tax: taxes,
    discount: couponStore.discount,
    total,
  };
});

const userFields = computed((): Map<keyof Customer, CustomerItem> => {
  const values = new Map();
  const isNaturalPerson = customer.value.clientType === false;

  const fields: Partial<Record<keyof Customer, CustomerItem>> = {
    name: {
      label: t('USER_INFO.NAME'),
      type: 'text',
      icon: 'account',
      info: t('USER_INFO.VALIDATIONS.BILLING_NAMES_NEEDED'),
      placeholder: isNaturalPerson
        ? t('USER_INFO.NAME_PLACEHOLDER')
        : t('USER_INFO.COMPANY_NAME'),
    },
    taxIdentificationNumber: {
      label: t('USER_INFO.DOCUMENT_ID'),
      type: 'number',
      icon: 'citizen-id',
      info: t('USER_INFO.VALIDATIONS.BILLING_ID_NEEDED'),
      placeholder: t('USER_INFO.DOCUMENT_PLACEHOLDER'),
    },
    phone: {
      label: t('USER_INFO.PHONE'),
      type: 'number',
      icon: 'mobile',
      info: '',
      placeholder: t('USER_INFO.PHONE_PLACEHOLDER'),
    },
    email: {
      label: t('USER_INFO.EMAIL'),
      type: 'email',
      icon: 'email',
      info: t('USER_INFO.VALIDATIONS.BILLING_NEEDED'),
      placeholder: t('USER_INFO.EMAIL_PLACEHOLDER'),
    },
  };

  // Set default field
  values.set('name', fields.name);

  if (clusterSettings.value?.basics.customerInfo) {
    // Set values
    const { customerInfo } = clusterSettings.value.basics;
    const keys = Object.keys(customerInfo) as Array<keyof Customer>;

    keys.forEach((key) => {
      // @ts-ignore
      if (customerInfo[key] && fields[key]) values.set(key, fields[key]);
    });
  }

  return values;
});

/**
 * Create a payment order with specific payment method
 */
const { createOrder } = useCreateOrder();
function selectPaymentMethod(isCash: boolean) {
  createOrder(isCash ? PaymentMethod.CASH : PaymentMethod.CARD);
}
function createOrderWithDifferentCurrency(currencyCode: string) {
  closeCurrencyModal();
  createOrder(PaymentMethod.CARD, currencyCode);
}

function nextStep() {
  const index = currentStepIndex.value;
  if (index !== -1) {
    collapseSteps.value[index].collapse = true;
    collapseSteps.value[index + 1].collapse = false;
    collapseSteps.value[index].checked = true;
  }
}

function previousStep() {
  const index = currentStepIndex.value;
  if (index > 0) {
    collapseSteps.value[index].collapse = true;
    collapseSteps.value[index - 1].collapse = false;
  }
}

function goBack() {
  router.push({ name: 'CartV2' });
  triggerGoBack(route.name);
}

onBeforeMount(() => {
  if (showClientType.value) {
    customer.value.clientType = false;
  }
});

onUnmounted(() => {
  hideSummary.value = true;
});
</script>

<template>
  <div class="full-hd flex flex-col">
    <section
      class="sticky top-0 z-20 flex items-center justify-center w-full pb-0 bg-neutral-200"
    >
      <div class="flex items-center w-full px-12 flex-nowrap">
        <KioskTimeline
          v-if="timelineImage"
          :steps="steps"
          :current-index="stepIndex(StepWizardName.PAY)"
          :active-image="timelineImage"
        />

        <KioskButton color="primary" class="flex-none !h-[68px] w-[108px] !py-2">
          <div class="flex items-center justify-center text-[32px] gap-1">
            <span class="icon icon-shopping-cart" />
            <span
              class="flex items-center justify-center p-1 text-black bg-white h-9 w-9 rounded-xs"
            >{{ itemsCount }}</span>
          </div>
        </KioskButton>
      </div>
    </section>

    <section class="flex flex-col justify-between flex-grow overflow-auto">
      <div class="options-container">
        <CollapsableContainer
          v-model="collapseSteps[0].collapse"
          :position="0"
          :title="t('CHECKOUT.CLIENT_INFO_TITLE')"
          :show-collapse="false"
        >
          <OrderCheckoutUserInfoV2
            v-model="customer"
            :show-client-type="showClientType"
            :user-fields="userFields"
            @confirm="nextStep()"
          />
        </CollapsableContainer>

        <TableNumber v-model="collapseSteps[1].collapse" @next="nextStep()" />

        <!-- Cart summary -->
        <CollapsableContainer
          v-model="collapseSteps[2].collapse"
          :position="2"
          :title="t('CHECKOUT.SUMMARY_TITLE')"
          :show-collapse="false"
        >
          <Payment :customer="customer" />
        </CollapsableContainer>
      </div>
    </section>

    <KioskFooter :z-index="false" @back="goBack()">
      <template #actions>
        <div class="flex gap-5 items-center">
          <button type="button" @click="previousStep()">
            <img src="/src/assets/svg/next-arrow.svg" alt="back-arrow" />
          </button>
          <span class="text-3xl">{{ currentStepIndex + 1 }}/3</span>
          <button
            type="button"
            class="disabled:opacity-50"
            :disabled="currentStepIndex === 3 || !collapseSteps[currentStepIndex].checked"
            @click="nextStep()"
          >
            <img src="/src/assets/svg/next-arrow.svg" class="rotate-180" alt="next-arrow" />
          </button>
        </div>
      </template>
    </KioskFooter>
  </div>
  <CurrencyModal
    v-if="isOpenCurrencyModal"
    @currency-selected="createOrderWithDifferentCurrency($event)"
    @close-modal="closeCurrencyModal()"
  />

  <OrderCheckoutModal
    v-if="paymentInProgress || jobResponse"
    :customer="customer"
    :order-total="orderTotal"
    @retry-cash="selectPaymentMethod(true)"
    @retry-card="selectPaymentMethod(false)"
  />
</template>

<style scoped>
.options-container {
  @apply flex flex-col my-12 z-20 bg-white;
}

.options-container:last-child {
  @apply flex-grow;
}

.user-checkbox {
  @apply w-7 h-7 mr-4;
}
</style>
