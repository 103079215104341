import { FulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { RouteName } from '../../../common/routes/routeName';

export function useFulfillmentRedirect() {
  const router = useRouter();
  const metadataStore = useMetadataStore();
  const orderTypeStore = useOrderTypeStore();

  const redirectFromFulfilment = () => {
    // if categories is disabled
    const redirectTo = metadataStore.hideCategories
      ? RouteName.PRODUCTS
      : RouteName.ORDER_CATEGORIES;

    router.push({ name: redirectTo });
  };

  const redirectFromWelcome = () => {
    const { kioskSettings } = metadataStore;
    if (!kioskSettings) {
      console.warn('there are not kiosk settings');
      return;
    }

    const { dineIn, pickUp } = kioskSettings.fulfillment;

    // Go to order type if both are enabled
    if (dineIn.enabled && pickUp.enabled) {
      router.push({ name: RouteName.ORDER_TYPE });
      return;
    }

    // Assume one of them as unique answer and ignore order type screen
    if (dineIn.enabled) {
      orderTypeStore.fulfillmentMode = FulfillmentMode.DINE_IN;
    }

    if (pickUp.enabled) {
      orderTypeStore.fulfillmentMode = FulfillmentMode.PICKUP;
    }

    redirectFromFulfilment();
  };

  return { redirectFromFulfilment, redirectFromWelcome };
}
