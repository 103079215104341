import { RouteName } from '../../../common/routes/routeName';
import { useTimeStore } from '../../../common/stores/time';

export const useStandbyBannerStore = defineStore('standbyBanner', () => {
  const timeStore = useTimeStore();

  const { now } = storeToRefs(timeStore);

  const openTodayTime = ref<Date>();
  const closingTodayTime = ref<Date>();
  const openTomorrowTime = ref<Date>();

  const isOpen = ref(true);

  const router = useRouter();
  const metadataStore = useMetadataStore();
  const settingsStore = useSettingsStore();

  const { kioskSchedules } = storeToRefs(metadataStore);

  /**
   * Sets the schedule for the standby banner.
   * @param todayOpenAt - The opening date for today.
   * @param todayCloseAt - The closing date for today.
   * @param nextDayOpenAt - The opening date for the next day.
   */
  function setSchedule(todayOpenAt: string, todayCloseAt: string, nextDayOpenAt: string) {
    const openingToday = new Date(todayOpenAt);
    openTodayTime.value = openingToday;

    const closingToday = new Date(todayCloseAt);
    closingTodayTime.value = closingToday;

    const openTomorrow = new Date(nextDayOpenAt);
    openTomorrowTime.value = openTomorrow;
  }

  /**
   * Checks the current time and updates the `isOpen` value based on the opening and closing times.
   */
  async function checkClose(nowTime: number) {
    if (!closingTodayTime.value || !openTomorrowTime.value || !openTodayTime.value) return;

    const close = closingTodayTime.value.getTime();
    const open = openTodayTime.value.getTime();
    const reopen = openTomorrowTime.value.getTime();

    if (nowTime < open) {
      isOpen.value = false;
      return;
    }

    if (open < nowTime && nowTime < close) {
      isOpen.value = true;
      return;
    }

    if (close < nowTime && nowTime < reopen) {
      isOpen.value = false;
      return;
    }

    if (reopen < nowTime) {
      timeStore.pause();
      await settingsStore.refreshMetadata();
      timeStore.resume();

      isOpen.value = true;
    }
  }

  /**
   * The handle for the watcher that monitors changes in kiosk schedules.
   */
  const unwatchSchedules = watch(kioskSchedules, (schedules) => {
    if (!schedules) return;

    setSchedule(
      schedules.openingTime,
      schedules.closingTime,
      schedules.reopeningTime,
    );
  }, { immediate: true });

  /**
   * Represents the watcher for the current time to check if the kiosk is open or closed.
   */
  const unwatchNow = watch(now, (currentDate) => {
    checkClose(currentDate.getTime());
  });

  /**
   * Represents the watcher for the `isOpen` value to redirect the user to the correct page.
   */
  const unwatchOpen = watch(isOpen, (open) => {
    if (open) {
      router.push({ name: RouteName.WELCOME });
      return;
    }

    router.push({ name: RouteName.STAND_BY_BANNER });
  }, { immediate: true });

  /**
   * Stops checking for updates by unwatching the necessary properties.
   */
  const stopChecking = () => {
    unwatchNow();
    unwatchOpen();
    unwatchSchedules();
  };

  return {
    isOpen,
    stopChecking,
    openTodayTime,
    closingTodayTime,
    openTomorrowTime,
  };
});
