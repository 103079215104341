<script lang="ts" setup>
import { RouteName } from '@/common/routes/routeName';
import { LanguageLocales } from '@/modules/language/types';

const router = useRouter();
const menuStore = useMenuStore();
const settingsStore = useSettingsStore();
const metadataStore = useMetadataStore();
const languageStore = useLanguageStore();
const standbyBannerStore = useStandbyBannerStore();
const maintenanceStore = useMaintenanceStore();
const fulfillmentRedirect = useFulfillmentRedirect();

const { gtmTransactionId } = storeToRefs(settingsStore);
const { banners, startButton } = storeToRefs(metadataStore);
const { isLanguageEditable, languages } = storeToRefs(languageStore);

const showLoader = computed(() => !metadataStore.kioskSettings || !metadataStore.kioskSettings);

async function startOrder(lang?: LanguageLocales) {
  const redirectTo = await maintenanceStore.getKioskStatus();

  if (lang) {
    languageStore.setLanguage(lang);
  }

  if (redirectTo) {
    router.push({ name: redirectTo });
    return;
  }

  fulfillmentRedirect.redirectFromWelcome();
}

/**
 * TODO: Migrate this logic into composable
 */
function beforeMount() {
  menuStore.reset();
  // Get data again
  gtmTransactionId.value = '';
  settingsStore.refreshMetadata();
}

// Set language with multi brand value
watch(() => languageStore.languages, (newData) => {
  if (newData && settingsStore.multibrandData) {
    const { language } = settingsStore.multibrandData;
    languageStore.setLanguage(language);
  }
});

onBeforeMount(() => beforeMount());

onBeforeRouteLeave((currentRoute) => {
  // Just preserve the checking of its close in standby banner and welcome view
  if (currentRoute.name !== RouteName.STAND_BY_BANNER) {
    standbyBannerStore.stopChecking();
  }
});
</script>

<template>
  <KioskLoading v-if="showLoader" />

  <template v-else>
    <button
      class="relative full-hd"
      type="button"
      @click.self="startOrder()"
    >
      <div
        v-if="banners?.length > 0"
        class="absolute top-0 z-0"
      >
        <ImageCarousel
          :data-images="banners"
          tailwind-classes="full-hd"
        />
      </div>

      <div class="menu-container">
        <template v-if="isLanguageEditable">
          <KioskButton
            v-for="languageItem in languages"
            :key="languageItem.id"
            class="menu-button"
            color="primary"
            text-size="large"
            @click="startOrder(languageItem.id)"
          >
            {{ languageItem.label }}
          </KioskButton>
        </template>

        <template v-if="!isLanguageEditable && startButton">
          <KioskButton
            class="menu-button"
            color="primary"
            text-size="large"
            @click="startOrder()"
          >
            {{ $t('ORDER_HERE') }}
          </KioskButton>
        </template>
      </div>
    </button>
  </template>
</template>

<style scoped>
.menu-container {
  @apply flex gap-12 absolute bottom-56 w-full justify-center;
}
.menu-button {
  @apply min-w-[320px];
}
</style>
